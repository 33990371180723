<template>
    <SectionLayout>
        <v-col cols="12" class="ma-0 pa-0">
        <v-row justify="center"  class="py-5">
            <v-col style="text-align: center">
                 <h1 class="display-2 font-weight-light">Cryptium VPN (alpha)</h1>
            </v-col>
        </v-row>
        <v-row justify="center"  class="py-5">
            <v-col style="text-align: center">
                 <p class="headline">We're still developing the service, and we'd love to hear from you!</p>
                 <p class="headline">Please <a href="/contact/" class="blue--text">contact us</a> to share your thoughts or ask questions</p>
            </v-col>
        </v-row>
        <v-row justify="center" class="pt-10">
            <v-btn elevation="4" class="blue white--text" to="/create-account">Create account</v-btn>
        </v-row>
        </v-col>
    </SectionLayout>
</template>

<style scoped>

</style>

<script>
import { mapState, mapGetters } from 'vuex';
import SectionLayout from '@/components/SectionLayout.vue';

export default {
    components: {
        SectionLayout,
    },
    computed: {
        ...mapState({
            isReady: (state) => state.isReady,
            session: (state) => state.session,
        }),
        ...mapGetters({
            currentAccount: 'account', // currently selected account object
        }),
        isAuthenticated() {
            return this.session.isAuthenticated;
        },
    },
    methods: {
        init() {
            if (this.isReady && this.isAuthenticated) {
                this.$router.push('/dashboard');
            }
        },
    },
    watch: {
        isReady(value, oldValue) {
            if (value && !oldValue) {
                this.init();
            }
        },
    },
    mounted() {
        this.init();
    },
};
</script>
